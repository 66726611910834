import React, { FunctionComponent, ReactNode } from 'react'
import { toast as toastifyToast, UpdateOptions, ToastId } from 'react-toastify'

// TODO: Move timeout logic into Toast itself
// TODO: Restore timout pause on hover
// TODO: Give progress indication on toast (circle arround close?)

interface ToastProps {
  closeButton?: boolean,
  closeToast?: () => void
}

const Toast: FunctionComponent<ToastProps> = ({
  closeButton = true,
  closeToast = () => {},
  children
}) => {
  return (
    <>
      {
        closeButton ? (
          <button className="delete" onClick={closeToast} />
        ) : false
      }
      {children}
    </>
  )
}

const toast = (string: ReactNode, options: UpdateOptions) => {
  const {closeButton = true, ...toastOptions} = options

  if (toastOptions.toastId && toastifyToast.isActive(toastOptions.toastId)) {
    return toastOptions.toastId
  }

  const toastId = toastifyToast(
    <Toast children={string} closeButton={!!closeButton} />,
    toastOptions
  )

  if (options.autoClose) {
    const timeout = typeof(options.autoClose) === 'boolean' ? 5000 : options.autoClose
    setTimeout(() => toastifyToast.dismiss(toastId), timeout)
  }

  return toastId
}

export default toast

export const configureToast = toastifyToast.configure

export const updateToast = (toastId: ToastId, options: UpdateOptions) => {
  const {render, closeButton = true, ...otherOptions} = options

  if (options.autoClose) {
    const timeout = typeof(options.autoClose) === 'boolean' ? 5000 : options.autoClose
    setTimeout(() => toastifyToast.dismiss(toastId), timeout)
  }

  toastifyToast.update(
    toastId,
    {
      render: <Toast children={render} closeButton={!!closeButton} />,
      ...otherOptions
    }
  )
}
