import React, { useRef, useState, FunctionComponent } from 'react'
import useComponentSize from '@rehooks/component-size' // TODO: replace with react-use-size
import useSettings from '../actions/use_settings'
import useClickOutside from '../actions/use_click_outside'
import { Link, useLocation } from "react-router-dom"

import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

import Hamburger from './hamburger'

import { spaces } from '../data'

interface HeaderDropdownProps {
  icon: IconName,
  ariaName: string,
  iconProps?: Omit<FontAwesomeIconProps, 'icon'>
}

const HeaderDropdown: FunctionComponent<HeaderDropdownProps> = ({icon, ariaName, iconProps, children}) => {
  const [open, setOpen] = useState(false),
    dropdownRef = useRef(null)

  useClickOutside(dropdownRef, () => { if(open) setOpen(false) })

  return (
    <div className="frame-bar-item">
      <div ref={dropdownRef} className={open ? "dropdown is-right is-active" : "dropdown is-right"}>
        <div className="dropdown-trigger">
          <button className='button' aria-haspopup="true" aria-controls={`dropdown-${ariaName}`} onClick={() => setOpen(a => !a)}>
            <FontAwesomeIcon icon={icon} size="1x" {...iconProps}/>
          </button>
        </div>
        <div className="dropdown-menu" id={`dropdown-${ariaName}`} role="menu">
          <div className="dropdown-content">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

interface Breadcrumb {
  name: string,
  href?: string,
}

type Tool = "switch-darkmode" | "toggle-page-size" | 'space-tools'  | 'defaults' | 'modal-defaults'

interface ToolProps {
  tools?: Array<Tool>
}

const DarkMode = () => {
  const [{darkMode}, updateSetting] = useSettings()

  const toggleSetting = () => {
    updateSetting('darkMode', true)
    setTimeout(() => {
      updateSetting('darkMode', false)
    }, 750)
  }

  return (
    <div className="dropdown-item">
      <span>
        <input className="switch is-rounded" type="checkbox" id="dark-mode-switch" onChange={toggleSetting} checked={darkMode} />
        <label htmlFor="dark-mode-switch">Dark mode</label>
      </span>
    </div>
  )
}

const PageTools = () => {
  const [{pageTextSize, pageFullWidth}, updateSetting] = useSettings()

  return (
    <div className="dropdown-item">
      <div className="buttons has-addons is-centered is-fullwidth">
        {
          ['small', 'medium', 'large'].map((size) => (
            <button className={pageTextSize === size ? 'button is-primary' : 'button'} onClick={() => updateSetting('pageTextSize', size)}>{size[0].toUpperCase()}</button>
          ))
        }
      </div>
      <span>
        <input className="switch is-rounded" type="checkbox" id="fullpage-switch" onChange={() => updateSetting('pageFullWidth', !pageFullWidth)} checked={pageFullWidth} />
        <label htmlFor="fullpage-switch">Full page</label>
      </span>
    </div>
  )
}

const SpaceTools = ({space}: {space: any}) => {
  return (<>
    <div className="dropdown-item">
      <div className="title is-7">Modules</div>
    </div>
    <div>
      <ul>
        {space.modules.map(({name}: {name: string}) => (
          <li className="dropdown-item"><a>{name}</a></li>
        ))}
      </ul>
    </div>
    <div className="dropdown-divider" />
    <div className="dropdown-item">
      <a>Settings</a>
    </div>
  </>)
}

const Tools = ({tools = ['defaults']}) => {
  const exactTools = [
   ...(tools.includes('defaults') ? ['switch-darkmode', 'space-tools'] : []),
   ...(tools.includes('modal-defaults') ? [] : []),
    ...tools
  ]

  const location = useLocation(),
    space = exactTools.includes('space-tools') && spaces.find(({href}) => href === location.pathname)

  return (
    <HeaderDropdown ariaName="tools" icon="dot-circle">
      { space ? <SpaceTools space={space} /> : null }
      {
        ((exactTools.includes('toggle-page-size') || exactTools.includes('switch-darkmode')) && space) ? (
          <div className="dropdown-divider" />
        ) : null
      }
      {
        (exactTools.includes('toggle-page-size') || exactTools.includes('switch-darkmode')) ? (
          <div className="dropdown-item">
            <div className="title is-7">Page Layout</div>
          </div>
        ) : null
      }
      { exactTools.includes('toggle-page-size') ? <PageTools /> : null }
      { exactTools.includes('switch-darkmode') ? <DarkMode /> : null }
    </HeaderDropdown>
  )
}

export interface FrameProps extends ToolProps {
  breadcrumbs?: Array<Breadcrumb>,
  title?: string,
  padding?: boolean,
  disableScroll?: boolean,
  flex?: boolean,
  isModal?: boolean
  header?: boolean,
  link?: string | boolean
  toggleOverview?: () => void,
  overview?: boolean
}

const Frame: FunctionComponent<FrameProps> = ({
  breadcrumbs = [],
  title = undefined,
  children = false,
  padding = false,
  disableScroll = false,
  flex = false,
  tools = undefined,
  header = false,
  isModal = false,
  link = true,
  toggleOverview,
  overview
}) => {
  const toolProps = {tools},
    frameRef = useRef(null),
    { width, /*height*/ } = useComponentSize(frameRef)

  const frameScrollClassNames = [
    'frame-scroll',
    disableScroll ? 'is-noscroll' : null,
  ].join(' ')

  const frameClassNames = [
    'frame-content',
    padding ? 'is-padding' : null,
    flex ? 'is-flex' : null,
  ].join(' ')

  return (
    <>
      <div className="frame-bar">
        <Hamburger />
        <ul className="frame-bar-breadcrumbs">
          {
            breadcrumbs.map(({name, href}) => (
              <li key={href} className="text-muted">
                <a href={href}>{name}</a>
                <FontAwesomeIcon icon="chevron-right" style={{margin: '0 0.5rem'}} />
              </li>
            ))
          }
          <li>{title}</li>
        </ul>
        {
          toggleOverview ? (
            <div className="frame-bar-item">
              <button className={`button${overview ? ' is-active' : ''}`} onClick={toggleOverview}>
                <span className="icon">
                  <FontAwesomeIcon icon="table" />
                  <div className="is-sr-only">Toggle overview</div>
                </span>
              </button>
            </div>
          ) : false
        }
        <HeaderDropdown ariaName="distribute" icon="sign-out-alt" iconProps={{transform: "rotate-270"}}>
          <div className="dropdown-item">
            Share this item
          </div>
        </HeaderDropdown>
        <Tools {...toolProps} />
        {
          isModal && link ? (
            <div className="frame-bar-item">
              {
                (typeof(link) === 'string') ? (
                  <Link to={link} className="button">
                    <FontAwesomeIcon icon="expand" />
                  </Link>
                ) : (
                  <button className="button">
                    <FontAwesomeIcon icon="expand" />
                  </button>
                )
              }
            </div>
          ) : false
        }
      </div>
      <div className={frameScrollClassNames}>
        {
          header ? (
            <div
              className="frame-header"
              style={{
                height: width / 4,
                backgroundImage: `url(https://images.unsplash.com/photo-1497366858526-0766cadbe8fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=480)`
              }}>
            </div>
          ) : false
        }
        <div ref={frameRef} className={frameClassNames}>
          {children}
        </div>
      </div>
    </>
  )
}

export default Frame
