import React, { FunctionComponent } from 'react'

import useSession from '../actions/use_session'

interface AccountSwitcherProps {
  onClose: () => void,
  onSwitchAccount: (id: string) => void
}

const AccountSwitcher: FunctionComponent<AccountSwitcherProps> = ({onClose, onSwitchAccount}) => {
  const {availableSessions} = useSession()

  return (
    <div className="modal is-active">
      <div onClick={onClose} className="modal-background"></div>
      <div className="modal-content modal-account-switcher">
        <h1 className="is-size-3" >Switch account</h1>
        <div className="card">
          <div className="card-content">
            {availableSessions.map(({id, account, name, avatar}) => (
              <a onClick={(e) => {e.preventDefault(); onSwitchAccount(id)}} className="media" href={`/switch_account/${id}`}>
                <div className="media-left">
                  <figure className="image is-48x48">
                    <img src={avatar.src} alt=""/>
                  </figure>
                </div>
                <div className="media-content">
                  <p className="title is-4">{account.name}</p>
                  <p className="subtitle is-6">{name}</p>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
      <button onClick={onClose} className="modal-close" aria-label="close"></button>
    </div>
  )
}

export default AccountSwitcher
