import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import { configureToast } from './actions/toast'

library.add(fab, fas, far)

configureToast({
  closeOnClick: false,
  closeButton: false,
  bodyClassName: 'notification is-info',
})

function loadPolyfills() {
  const polyfills = []

  if (!supportsResizeObserver()) {
    polyfills.push(import('resize-observer-polyfill'))
  }

  return Promise.all(polyfills)
}

function supportsResizeObserver() {
  return (
    typeof (global as any)['ResizeObserver'] !== 'undefined'
  )
}

loadPolyfills()
  .then(() => {
    ReactDOM.render(<App />, document.getElementById('root'))
  })


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
