import { useLocalStorage } from '@rehooks/local-storage'
import { useHistory } from "react-router-dom"

const SESSION_VERSION = 'is3'

interface Account {
  id: string,
  name: string
  // avatar: Avatar,
}

interface Avatar {
  src: string
}

interface Session {
  signedIn: boolean,
  email?: string,
  name?: string
  avatar?: Avatar,
  account?: Account
}

const AvailableSessions = [
  {
    id: 'one',
    signedIn: true,
    email: 'bert@bertg.be',
    name: 'Bert Goethals',
    account: {
      id: 'wayne',
      name: 'Wayne Company'
    },
    avatar: {
      src: 'https://en.gravatar.com/userimage/3258584/a73ec959f77c404d43d3a1d622949918.jpeg'
    }
  },
  {
    id: 'two',
    signedIn: true,
    email: 'bert@bertg.be',
    name: 'Bert',
    account: {
      id: 'acme',
      name: 'ACME Toons'
    },
    avatar: {
      src: 'https://pbs.twimg.com/profile_images/586609437424529408/8TkjBzuQ.jpg'
    }
  }
]

interface useSessionResult {
  session: Session,
  signIn: () => void,
  signOut: () => void,
  changeSession: (id: string) => void,
  availableSessions: any[]
}

const useSession = (): useSessionResult => {
  const [session, setSession, ] = useLocalStorage<Session>('session', {signedIn: false}),
    [sessionVersion, setSessionVersion, ] = useLocalStorage<string>('session_version', SESSION_VERSION),
    history = useHistory()

  const signOut = () => {
    setSessionVersion(SESSION_VERSION)
    setSession({signedIn: false})
    history.push('/sign_in')
  }

  const signIn = () => {
    setSessionVersion(SESSION_VERSION)
    setSession(AvailableSessions[0])
    history.push('/home')
  }

  const changeSession = (sessionId: string) => {
    const newSession = AvailableSessions.find(({id}) => sessionId === id)
    if (newSession) {
      setSessionVersion(SESSION_VERSION)
      setSession(newSession)
    }
  }

  if (sessionVersion !== SESSION_VERSION) {
    setSessionVersion(SESSION_VERSION)
    signOut()
  }

  return {
    session: (session || {signedIn: false}),
    availableSessions: AvailableSessions,
    changeSession,
    signIn,
    signOut
  }
}


export default useSession
