import React, { useState, useRef } from 'react'
import { NavLink as Link, useLocation, matchPath } from "react-router-dom"
import useSession from '../actions/use_session'
import useSetting from '../actions/use_settings'
import useClickOutside from '../actions/use_click_outside'

import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Hamburger from './hamburger'
import Logo from './logo'

import { spaces as MY_SPACES } from '../data'

const MY_LINKS = [
  {name: 'Home', href: '/home', icon: 'home'},
  {name: 'Calendar', href: '/calendar', icon: 'calendar'},
  {name: 'My Vault', href: '/vault', icon: 'archive', count: 3},
  {name: 'All files', href: '/files', icon: 'folder-open'},
]
const ADMIN_TOOLS = [
  {name: "Vault Manager",   href: '/vault/manager', icon: 'archive'},
  {name: "System Settings", href: '/system', icon: 'cog'},
  {name: "Help",            href: '/examples/votes', icon: 'question-circle'}
]

const OldMenu = ({openAccountSwitcher}: {openAccountSwitcher: () => void}) => {
  const [accountMenuVisible, setAccountMenuVisible] = useState(false),
    {signOut, session} = useSession(),
    location = useLocation()

  const spaces = MY_SPACES.map(({href, ...props}) => ({
    href,
    ...props,
    isActive: matchPath(location.pathname, {path: href})
  }))

  return (
    <div className="main-menu">
      <div className="menu--search ">
        <div className="control has-icons-left">
          <input type="text" className="input" placeholder="Search..." />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon="search" />
          </span>
        </div>
      </div>
      <div className="menu--navigation">
        <nav className="menu">
          <ul className="menu-list">
          {
            MY_LINKS.map(({name, href, icon, count}) => (
              <li key={href}>
                <Link to={href} activeClassName="is-active" exact>
                  <FontAwesomeIcon icon={icon as IconName} fixedWidth />
                  <span className="menu--item--name">{name}</span>
                  <span className="tag is-rounded is-danger">{count}</span>
                </Link>
              </li>
            ))
          }
          </ul>
          <p className="maenu-label">Spaces</p>
          <ul className="menu-list">
          {
            spaces.map(({name, href, icon, isActive, modules}) => (
              <li key={href}>
                <Link to={href} activeClassName="is-active">
                  <FontAwesomeIcon icon={icon as IconName} fixedWidth />
                  {name}
                </Link>
                <ul className={isActive ? '' : 'is-hidden'}>
                  {modules.map(({name}) => (
                    <li><a>{name}</a></li>
                  ))}
                </ul>
              </li>
            ))
          }
          </ul>
          <p className="maenu-label">Other</p>
          <ul className="menu-list">
          {
            ADMIN_TOOLS.map(({name, href, icon}) => (
              <li key={href}>
                <Link to={href} activeClassName="is-active">
                  <FontAwesomeIcon icon={icon as IconName} fixedWidth />
                  {name}
                </Link>
              </li>
            ))
          }
          </ul>
        </nav>
      </div>
      <div className="menu--account">
        {
          accountMenuVisible ? (
            <>
              <a href={"/sign_out"} onClick={(e) => {
                e.preventDefault()
                signOut()
              }} className="menu--account--item">
                <FontAwesomeIcon icon="power-off" />{' '}
                Sign out
              </a>
              <button onClick={openAccountSwitcher} className="menu--account--item">
                <FontAwesomeIcon icon="random" />{' '}
                Change account
              </button>
            </>
          ) : false
        }
        <div className="menu--account--toggle" onClick={() => setAccountMenuVisible(a => !a)}>
          <img className="menu--account--avatar" src={session?.avatar?.src} alt="" />
          <div className="menu--account--name">{session?.name}</div>
          <div className="menu--account--carret">
            <FontAwesomeIcon icon="chevron-up" />
          </div>
        </div>
      </div>
    </div>
  )
}

export const useMenuVisible: () => [boolean, boolean, () => void, () => void] = () => {
  const [{menuOpen, menuPeek}, updateSettings] = useSetting()

  const update = () => {
    console.log("updatingMenu", {menuOpen})
    if (menuOpen) {
      updateSettings([
        {setting: 'menuOpen', value: false},
        {setting: 'menuPeek', value: false},
      ])
    } else {
      updateSettings([
        {setting: 'menuOpen', value: true},
        {setting: 'menuPeek', value: false},
      ])
    }
  }
  const togglePeek = () => updateSettings('menuPeek', !menuPeek)

  return [!!menuOpen, !!menuPeek, update, togglePeek]
}

interface MenuProps {

}

const Menu = ({openAccountSwitcher}: {openAccountSwitcher: () => void}) => {
  const [visible, peeking, , togglePeek] = useMenuVisible(),
    menuRef = useRef(null)

  useClickOutside(menuRef, () => {
    if (peeking) togglePeek()
  })

  const menuClassName = [
    'sidebar',
    visible ? false : 'is-collapsed',
    peeking ? 'is-peeking' : false
  ].filter(i => i).join(' ')

  return (
    <div ref={menuRef} className={menuClassName}>
      <div className="sidebar-head">
        <Logo />
        <Hamburger inMenu />
      </div>
      <OldMenu openAccountSwitcher={openAccountSwitcher} />
    </div>
  )
}


export default Menu
