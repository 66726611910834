import React from 'react'
import Frame from '../layout/frame'
import Placeholder from './placeholder'

const PlaceholderFrame = () => (
  <Frame title="Placeholder">
    <Placeholder />
  </Frame>
)

export default PlaceholderFrame
