import React, { FunctionComponent, useState } from 'react'

import Menu, { useMenuVisible } from './menu'
import AccountSwitcher from './account_switcher'
import useSession from '../actions/use_session'
import toast, { updateToast } from '../actions/toast'

interface LayoutProps {
  publicPage?: boolean
}

interface AppProps {
  banner?: boolean
}

const App: FunctionComponent<AppProps> = ({banner = false, children}) => {
  const [contentBanner,] = useState(false),
    [accountSwitcherVisible, setAccountSwitcherVisible] = useState(false),
    [menuVisible, ] = useMenuVisible(),
    [switchingAccount, setSwitchingAccount] = useState(false),
    {session, changeSession} = useSession()

  const switchAccount = (id: string) => {
    setSwitchingAccount(true)
    setAccountSwitcherVisible(false)
    const toastId = toast('Switching account...', {
      autoClose: false,
      closeButton: false
    })
    setTimeout(() => {
      updateToast(
        toastId, {
          render: 'Switched Account!',
          closeButton: true,
          autoClose: 5000
        }
      )
      changeSession(id)
      setSwitchingAccount(false)
    }, 5000);
  }

  if (switchingAccount) {
    return (
      <div className="app">
        <div className="bg-rainbow" style={{width: '100%', height: '100%', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
          <div className="loader" style={{fontSize: '4rem'}} />
          <div>Switching account</div>
        </div>
      </div>
    )
  }

  const appClassName = [
    'app',
    banner ? 'has-banner-on-top' : false,
    menuVisible ? 'has-sidebar-visible' : false
  ].filter(i => i).join(' ')

  return (
    <div className={appClassName} data-brand={session?.account?.id}>
      <Menu openAccountSwitcher={() => setAccountSwitcherVisible(true)} />
      <div className="frame">
        {
          contentBanner ? (
            <div className="frame-banner notification is-info is-light">
              <div className="content">
                {"This is an example content banner. For example to be used in case of   "}
                <strong>customer super important news</strong>
              </div>
            </div>
          ) : false
        }

        {children}
      </div>
      {
        accountSwitcherVisible ? <AccountSwitcher onSwitchAccount={switchAccount} onClose={() => setAccountSwitcherVisible(false)} /> : false
      }
    </div>
  )
}

const Layout: FunctionComponent<LayoutProps> = ({children, publicPage = false}) => {
  const [banner,] = useState(false)

  return (
    <>
      {
        banner ? (
          <div className='global-banner notification is-warning is-light'>
            <div className="content">
              {"This is an example technical banner. For example to be used in case of   "}
              <strong>read only mode</strong>
            </div>
          </div>
        ) : false
      }
      {
        publicPage ? children : <App banner={banner} >{children}</App>
      }
    </>
  )
}

export default Layout
