import { snakeCase, sortBy } from 'lodash'
import profileResources from "./profiles.json"
import spaces from './spaces.json'
import eventsJson from "../data/events.json"

const {resources: eventResources, linked: {contact_lines: eventLocations}} = eventsJson

const thumbnail = ((src: string, id: string) => {
  if (src[0] === '/') {
    return `https://younited.trustedfamily.net${src}`
  } else {
    return `/uploads/profile_images/${id}.png`
  }
})

const TAGS_MAP = new Map([
  ['c94cc6d5-72ff-4d0c-9817-3f2d45b4201c', ['root', 'billing']],
  ['adaa1a47-920b-43b2-bfbf-3c93339e10d4', ['root', 'billing']],
  ['562f9f9e-2466-43d6-a688-6a8258a07ba5', ['billing']],
])

const tags = ({id}: {id: string}) => {
  return TAGS_MAP.get(id) || []
}

const profiles = sortBy(
  profileResources.map((
    {
      id,
      preview: {
        name,
        thumbnail: {href}
      },
      data: {
        surname,
        firstnames
      }
    }
  ) => ({
    id,
    name,
    surname,
    firstnames,
    email: `${snakeCase(name).replace(/_/g, '.')}@example.com`,
    image: {src: thumbnail(href, id)},
    type: 'member'
  })),
  ['surname', 'firstnames']
).map((profile) => ({
  ...profile,
  tags: tags(profile)
}))

const guests = [
  {
    id: 'rdj',
    name: 'Robert Downey Jr.',
    surname: 'Downey',
    firstnames: 'Robert Jr.',
    image: {src: "/uploads/profile_images/rdj.png"}
  },
  {
    id: 'twi',
    name: 'Taika Waititi',
    surname: 'Taika',
    firstnames: 'Waititi',
    image:  {src: "/uploads/profile_images/twi.png"}
  },
  {
    id: 'mro',
    name: 'Mark Ruffalo',
    surname: 'Mark',
    firstnames: 'Ruffalo',
    image:  {src: "/uploads/profile_images/mro.png"}
  },
].map((profile) => ({
  ...profile,
  email: `${snakeCase(profile.name).replace(/_/g, '.')}@example.com`,
  type: 'guest',
  tags: []
}))

const profileAndGuests = sortBy([...profiles, ...guests], ['surname', 'firstnames'])

const events = eventResources.map((
  {
    id,
    preview: {
      name
    },
    links: {location}
  }
) => ({
  id,
  name,
  location: eventLocations.find(({id}) => id === location?.id)?.preview?.name
}))

const BOARD_IDS = [
  'a3669890-2967-4bdd-a58f-8f009eb3c00e',
  '0b969223-06c7-4259-88b3-5229c5416e0f',
  'a74436f1-d175-4bb4-8ebe-de3150f45deb',
  '01ceaf4b-fa89-430e-b876-1d23c69f0dc2',
  '89e715e0-56fd-4d38-97eb-69da396b1afe',
  '7d3cfc24-b2b5-4114-b5ba-83ea2d83d98d'
]

const board = {
  ...spaces[0],
  members: BOARD_IDS
    .map((bid) => profiles.find(({id}) => bid === id))
    .map((profile, i) => ({
      attended: (i + 1) !== BOARD_IDS.length,
      ...(profile as any)
    }))
}

const SHAREHODLER_IDS = [
  'a3669890-2967-4bdd-a58f-8f009eb3c00e',
  '0b969223-06c7-4259-88b3-5229c5416e0f',
  '13403ff5-5c06-413a-bb3d-f022918128ba'
]

const shareholders = {
  ...spaces[2],
  members: SHAREHODLER_IDS
    .map((bid) => profiles.find(({id}) => bid === id))
    .map((profile, i, allProfiles) => ({
      attended: (i + 1) !== SHAREHODLER_IDS.length,
      shares: 2500 * (SHAREHODLER_IDS.length - i),
      proxy: i === 2 ? allProfiles[1] : null,
      ...(profile as any)
    }))
}

const corp = {
  ...spaces[1],
  members: []
}

export { profiles, spaces, events, board, shareholders, corp, guests, profileAndGuests }
