import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom"

import useSession from './actions/use_session'
import loadable from '@loadable/component'

// Drag and Drop support down the line
import Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import Layout from './layout'
import PlaceholderFrame from './debug/placeholder_frame'

const Loader: any = (arg0: any) => (<div className="page-loader"><div className="loader" /><p>Loading...</p></div>)


const About         = loadable(() => import('./info/about'), { fallback: <Loader /> })
const Vault         = loadable(() => import('./vault'), { fallback: <Loader /> })
const VaultManager  = loadable(() => import('./vault_manager'), { fallback: <Loader /> })
const Calendar      = loadable(() => import('./calendar'), { fallback: <Loader /> })
const SignIn        = loadable(() => import('./sign_in'), { fallback: <Loader /> })
const Votes         = loadable(() => import('./votes'), { fallback: <Loader /> })
const GraphTest     = loadable(() => import('./graph_test'), { fallback: <Loader /> })
const UploadTest     = loadable(() => import('./upload_test'), { fallback: <Loader /> })

const Corp          = loadable(() => import('./examples/corp'), { fallback: <Loader /> })
const Board         = loadable(() => import('./examples/board'), { fallback: <Loader /> })
const Shareholders  = loadable(() => import('./examples/shareholders'), { fallback: <Loader /> })
const FamilyHistory = loadable(() => import('./examples/family_history'), { fallback: <Loader /> })
const AllFiles      = loadable(() => import('./examples/all_files'), { fallback: <Loader /> })
const Home          = loadable(() => import('./examples/home'), { fallback: <Loader /> })

const Meeting       = loadable(() => import('./examples/meeting_page'), { fallback: <Loader /> })

const SytemSettings = loadable(() => import('./system_settings'), { fallback: <Loader /> })

const App = () => {
  const {session} = useSession()

  if (session.signedIn) {
    return (
      <DndProvider backend={Backend}>
        <Layout>
          <Switch>
            <Route path="/home">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/files">
              <AllFiles />
            </Route>
            <Route path="/vault/manager">
              <VaultManager />
            </Route>
            <Route path="/vault">
              <Vault />
            </Route>
            <Route path="/calendar">
              <Calendar />
            </Route>
            <Route path="/system">
              <SytemSettings />
            </Route>
            <Route path="/examples/votes">
              <Votes />
            </Route>

            <Route path="/s/23">
              <Board />
            </Route>
            <Route path="/s/12">
              <Corp />
            </Route>
            <Route path="/s/95">
              <Shareholders />
            </Route>
            <Route path="/s/47">
              <FamilyHistory />
            </Route>
            <Route path="/c/8sjye67">
              <Meeting />
            </Route>
            <Route path="/graphtest">
              <GraphTest />
            </Route>
            <Route path="/uploadtest">
              <UploadTest />
            </Route>

            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route path="*">
              <PlaceholderFrame />
            </Route>
          </Switch>
        </Layout>
      </DndProvider>
    )
  } else {
    return (
      <Layout publicPage>
        <Route path="/sign_in">
          <SignIn />
        </Route>
        <Route path="*">
          <Redirect to="/sign_in" />
        </Route>
      </Layout>
    )
  }
}

const RoutedApp = () => (
  <Router>
    <App />
  </Router>
)

export default RoutedApp
