import { useEffect } from 'react'

const useEvent = (
  event: string,
  handler: EventListenerOrEventListenerObject,
  passive = false
): void  => {
  useEffect(() => {
    window.addEventListener(event, handler, passive)
    return () => { window.removeEventListener(event, handler) }
  })
}

const useClickOutside = (ref: React.RefObject<HTMLInputElement>, callback: () => void) => {
  const handleClickOutside = ({target}: {target: any}) => {
    if (!ref.current?.contains(target)) {
      callback()
    }
  }

  useEvent('mousedown', handleClickOutside)
  useEvent('mouseup', handleClickOutside)
}

export default useClickOutside
