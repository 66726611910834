import { useLocalStorage } from '@rehooks/local-storage'
import { useEffect } from 'react'
import toast from '../actions/toast'

type StateValue = string | boolean
type StateSetting = 'pageTextSize' | 'pageFullWidth' |
  'darkMode' | 'menuOpen' | 'menuPeek'

interface StateChange {
  setting: StateSetting,
  value: StateValue
}

interface State {
  pageTextSize?: 'small' | 'medium' | 'large',
  pageFullWidth?: boolean,
  darkMode?: boolean,
  menuOpen?: boolean
  menuPeek?: boolean
}

const DefaultState: State = {
  pageTextSize: 'medium', // small medium large
  pageFullWidth: false, // full
  darkMode: document.getElementsByTagName("BODY")[0].classList.contains('dark'),
  menuOpen: true,
  menuPeek: false
}

const doToggleDarkMode = (checked: boolean) => {
  const bodyClassList = document.getElementsByTagName("BODY")[0].classList
  if (!!checked) {
    bodyClassList.remove('light')
    bodyClassList.add('dark')
  } else {
    bodyClassList.add('light')
    bodyClassList.remove('dark')
  }
}

function reduceSetting<T extends unknown>(setting: StateSetting, value: T, newValue: T): T {
  switch (setting) {
    case 'pageTextSize':
      return (['small', 'medium', 'large'].includes(newValue as string) ? newValue : value)
    case 'darkMode':
      if (newValue) {
        toast("Dark mode does not work yet.", {toastId: 'darkmode', autoClose: 2000})
      }
      return newValue
    case 'pageFullWidth':
    case 'menuOpen':
    case 'menuPeek':
      return [true, false].includes(newValue as boolean) ? newValue : value
    default:
      throw new Error();
  }
}

const UPDATE = 'UPDATE'

interface Action {
  type: string,
  payload: Array<StateChange>
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case UPDATE:
      return action.payload
        .map(({setting, value}) => {
          const currentValue = state[setting] as StateValue
          return { setting, value: reduceSetting(setting, currentValue, value) }
        })
        .reduce(
          (state, {setting, value}) => {
            return {
              ...state,
              [setting]: value
            }
          },
          state
        )
    default:
      throw new Error();
  }
}

interface updateSettingBuilder {
  state: State,
  updateState: (s: State) => void
}
type updateSettingArgs = {
  (s: StateChange, _?: undefined): void
  (s: Array<StateChange>, _?: undefined): void
  (setting: StateSetting, value: StateValue): void
}
type buildUpdateSettingArgs = {
  (s: updateSettingBuilder): updateSettingArgs
}


const buildUpdateSetting: buildUpdateSettingArgs = ({state, updateState}) => {
  const updateSetting = (arg1: any, arg2: any) => {
    let newState;

    if (arg2 !== undefined) {
      newState = reducer(state, {
        type: UPDATE,
        payload: [{setting: arg1, value: arg2}]
      })
    } else if (!Array.isArray(arg1)) {
      newState = reducer(state, {
        type: UPDATE,
        payload: [arg1]
      })
    } else {
      newState = reducer(state, {
        type: UPDATE,
        payload: arg1
      })

    }
    updateState(newState)
  }
  return updateSetting as updateSettingArgs
}


const useSettings = (): [State, updateSettingArgs] => {
  const [unsafePersistedSettings, setPersistedSettings, ] = useLocalStorage<State>('settings', DefaultState),
    persistedSettings = unsafePersistedSettings || DefaultState

  useEffect(
    () => doToggleDarkMode(!!persistedSettings.darkMode),
    [persistedSettings.darkMode]
  )

  const updateSettings = buildUpdateSetting({state: persistedSettings, updateState: setPersistedSettings})

  return [persistedSettings, updateSettings]
}

export default useSettings
