import React, { memo, FunctionComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useMenuVisible } from './menu'

interface HamburgerProps {
  inMenu?: boolean
}

const Hamburger: FunctionComponent<HamburgerProps> = ({inMenu = false}) => {
  const [visible, , toggleVisible, togglePeek] = useMenuVisible()

  if (!inMenu && visible) return null

  return (
    <div className="hamburger" onClick={inMenu ? toggleVisible : togglePeek}>
      {
        visible ? (
          <span className="fa-layers fa-1x">
            <FontAwesomeIcon icon="chevron-left" transform="left-4" />
            <FontAwesomeIcon icon="chevron-left" transform="right-4" />
          </span>
        ) : (
          inMenu ? (
            <FontAwesomeIcon icon="thumbtack" transform="rotate-45" />
          ) : (
            <FontAwesomeIcon icon="bars" size="1x" />
          )
        )
      }
    </div>
  )
}

export default memo(Hamburger);
