import React from 'react'

const Placeholder = () => (
  <div style={{width: '100%', height: '100%', backgroundColor: 'purple', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    Placeholder
    <div className="loader" />
  </div>
)

export default Placeholder
